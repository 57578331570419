<template>
  <v-row
  >
    <v-col
      cols="5"
      xs="5"
      class="text-md-start"
    >
      <v-btn
        small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="info darken-2"
        :to="{ name: 'savings.topup_create' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Create TopUp
      </v-btn>
    </v-col>
    <v-col
      cols="5"
      xs="5"
      class="text-md-end"
    >
      <v-btn
        small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'savings.topup_recharge' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Recharge TopUp
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-row class="pt-4 mx-2 pt-0">
          <v-col
            cols="12"
            class="px-4 pt-2 mt-1 mb-0 pb-0"
          >
            <v-select
              v-model="selectedZoneFilter"
              ref="selectedZoneFilter"
              outlined
              dense
              item-value="id"
              item-text="info"
              :items="zone_types"
              label="Filter Clients By Zone"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class=" mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class=" mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class=" mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-end"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getTopUps()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{ requestedTopUps.length }}) List Of TopUps</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="requestedTopUps"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.username`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.topup_zone }}: {{ item.username }}</span>
              <small>{{ item.no_campaign_transactions }} & {{ item.no_loan_transactions  }}</small>
            </div>
          </template>
          <template #[`item.amount`]="{item}">
            {{ item.amount }} F CFA
          </template>
          <template #[`item.amount_deposited_loan`]="{item}">
            {{ item.amount_deposited_loan }}
          </template>
          <template #[`item.amount_deposited_campaign`]="{item}">
            {{ item.amount_deposited_campaign }}
          </template>
          <template #[`item.amount_withdrawn_campaign`]="{item}">
            {{ item.amount_withdrawn_campaign }}
          </template>
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[item.status]"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Active: 'primary',
      Validated: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Principal Campaign', value: 'amount' },
        { text: 'Loan Deposits', value: 'amount_deposited_loan' },
        { text: 'Campaign Deposit', value: 'amount_deposited_campaign' },
        { text: 'Withdrawals', value: 'amount_withdrawn_campaign' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      selectedZoneFilter: "",
      zone_types: [],
      requestedTopUps: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      // this.getTopUps();
      document.title = "Manager: Savings | List of TopUps"
      this.getZones()
  },

  methods:{
    async getZones(){
      await axios
        .get('/api/v1/manager/get/topup/zones/')
        .then(response => {
          this.zone_types = response.data  // get the data and fill into campaigns
          console.log(this.zone_types)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },


    async getTopUps(){
      if (this.selectedZoneFilter === ''){
        this.$store.commit('setSnackBarMessage', "Please select a zone")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.selectedZoneFilter.focus()  // this causes a an auto fucos to the element
      }
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }

      this.loading = true
      await axios
        .get('/api/v1/manager/get/topups/'+this.selectedZoneFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.requestedTopUps = response.data  // get the data and fill into campaigns
          console.log(this.requestedTopUps)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },
  },
}
</script>
